import { useCurrency } from "@hooks/currency";
import { userSelectedCurrencyState } from "@states/common";
import { Json } from "../../../types";
import { useCallback, useMemo } from "react";
import { useRecoilValue } from "recoil";

export const GetAuctionForm = (auctionFormData: any) => {
  const { code } = useRecoilValue(userSelectedCurrencyState);
  const {
    product,
    category,
    startPrice,
    currentLoanBalance,
    buynowPrice,
    stepPrice,
    reservePrice,
  } = auctionFormData;
  const { bondPointToAmount, formatCurrencyNumber } = useCurrency();

  const isSba7LoanActive = useMemo(
    () => (product === "SBA-7" && category === "Loans" ? true : false),
    [category, product]
  );

  const sbaStartPrice = useMemo(() => {
    return (
      "$" +
      formatCurrencyNumber(bondPointToAmount(currentLoanBalance, startPrice), 2)
    );
  }, [startPrice, currentLoanBalance]);

  const sbaBuyPrice = useMemo(() => {
    return (
      "$" +
      formatCurrencyNumber(
        bondPointToAmount(currentLoanBalance, buynowPrice),
        2
      )
    );
  }, [buynowPrice, currentLoanBalance]);

  const sbaBPriceStep = useMemo(() => {
    return (
      "$" +
      formatCurrencyNumber(bondPointToAmount(currentLoanBalance, stepPrice), 2)
    );
  }, [stepPrice, currentLoanBalance]);

  const sbaReservePrice = useMemo(() => {
    return (
      "$" +
      formatCurrencyNumber(
        bondPointToAmount(currentLoanBalance, reservePrice),
        2
      )
    );
  }, [reservePrice, currentLoanBalance]);


  const currency = useCallback((bp: string) => {
    return isSba7LoanActive ? (parseFloat(bp) > 1 ? "Bond points" : "Bond point") : "USD";
  }, [auctionFormData])

  const assetClassicForm = [
    {
      header: "Price",
      form: [
        {
          title: "Start price",
          type: "input",
          inputType: "tel",
          symbol: currency(startPrice),
          name: "startPrice",
          required: true,
          pattern: "^[0-9]{0,7}(?:[.][0-9]{0,4})?$",
          description: isSba7LoanActive
            ? "Equivalent value : " + sbaStartPrice + " " + code
            : "",
        },
        {
          title: "Price step",
          type: "input",
          inputType: "tel",
          symbol: currency(stepPrice),
          name: "stepPrice",
          description: isSba7LoanActive
            ? "Equivalent value : " + sbaBPriceStep + " " + code
            : "",
          required: true,
          pattern: isSba7LoanActive
            ? "^[0-9]{0,7}(?:[.][0-9]{0,4})?$"
            : "^[0-9]{0,12}$",
        },
        {
          title: "Buy now",
          type: "input",
          inputType: "tel",
          symbol: currency(buynowPrice),
          name: "buynowPrice",
          required: isSba7LoanActive ? false : true,
          pattern: "^[0-9]{0,7}(?:[.][0-9]{0,4})?$",
          description: isSba7LoanActive
            ? "Equivalent value : " + sbaBuyPrice + " " + code
            : "",
        },
      ],
    },
    {
      header: "Steps",
      form: [
        {
          title: `Anti-sniping start time`,
          type: "input",
          inputType: "tel",
          symbol: "Sec",
          name: "antiSnipTimeStep",
          pattern: "^[0-9]{0,8}$",
        },
        {
          title: "Extension time",
          type: "input",
          inputType: "tel",
          symbol: "Sec",
          name: "antiSnipTimeToAdd",
          pattern: "^[0-9]{0,8}$",
        },
        // {
        //   title: "Bid agent enabled",
        //   type: "check",
        //   name: "isBidAgentEnabled",
        // },
      ],
    },
    {
      header: "Auction Period",
      form: [
        {
          title: "Auction starts at",
          type: "date",
          inputType: "date",
          symbol: "",
          name: "startTime",
          required: true,
        },
        {
          title: "Auction ends at",
          type: "date",
          inputType: "date",
          symbol: "",
          name: "endTime",
          required: true,
        },
      ],
    },
    // {
    //   header: "Auto restart description",
    //   form: [
    //     {
    //       title: "Auto restart",
    //       type: "check",
    //       name: "autoRestart",
    //     },
    //   ],
    // },
    // {
    //   header: "Repeat Auction",
    //   form: [
    //     {
    //       title: "Restart size",
    //       type: "input",
    //       inputType: "number",
    //       symbol: "",
    //       name: "restartSize",
    //     },
    //   ],
    // },
    // {
    //   header: "Auction Visibility",
    //   form: [
    //     {
    //       title: "Display in the catalog after completion",
    //       type: "check",
    //       name: "auctionVisibility",
    //     },
    //   ],
    // },
  ];

  const assetDutchForm = [
    {
      header: "Settings",
      form: [
        {
          title: "Start price",
          type: "input",
          inputType: "tel",
          symbol: currency(startPrice),
          name: "startPrice",
          required: true,
          pattern: "^[0-9]{0,7}(?:[.][0-9]{0,4})?$",
          description: isSba7LoanActive
            ? "Equivalent value : " + sbaStartPrice + " " + code
            : "",
        },
        {
          title: "Price step",
          type: "input",
          inputType: "tel",
          symbol: currency(stepPrice),
          name: "stepPrice",
          description: isSba7LoanActive
            ? "Equivalent value : " + sbaBPriceStep + " " + code
            : "",
          required: true,
          pattern: isSba7LoanActive
            ? "^[0-9]{0,7}(?:[.][0-9]{0,4})?$"
            : "^[0-9]{0,12}$",
        },
        {
          title: "Reserve Price",
          inputType: "tel",
          type: "input",
          name: "reservePrice",
          symbol: currency(reservePrice),
          required: true,
          pattern: "^[0-9]{0,7}(?:[.][0-9]{0,4})?$",
          description: isSba7LoanActive
            ? "Equivalent value : " + sbaReservePrice + " " + code
            : "",
        },
      ],
    },
    {
      header: "Time step",
      form: [
        {
          title: "Hours",
          inputType: "tel",
          type: "input",
          name: "timeStepHours",
          symbol: "",
          pattern: "^[0-9]{0,8}$",
        },
        {
          title: "Minutes",
          inputType: "tel",
          type: "input",
          name: "timeStepMinutes",
          symbol: "",
          pattern: "^[0-9]{0,8}$",
          required: true,
        },
      ],
    },
    {
      header: "Dutch inform",
      form: [
        {
          title: "Number of steps",
          inputType: "tel",
          type: "input",
          name: "dutchStepQuantity",
          symbol: "",
          readonly: true,
          pattern: "^[0-9]{0,15}$",
        },
        {
          title: "max Duration",
          inputType: "tel",
          type: "input",
          name: "dutchDuration",
          symbol: "Mins",
          readonly: true,
          pattern: "^[0-9]{0,8}$",
        },
      ],
    },
    {
      header: "Auction period",
      form: [
        {
          title: "Auction starts at",
          type: "date",
          inputType: "date",
          name: "startTime",
          symbol: "",
          required: true,
        },
        {
          title: "Auction ends at",
          type: "date",
          inputType: "date",
          name: "endTime",
          symbol: "",
          required: true,
        },
      ],
    },
    // {
    //   header: "Auto restart description",
    //   form: [
    //     {
    //       title: "Auto restart",
    //       name: "autoRestart",
    //       type: "check",
    //     },
    //   ],
    // },
    // {
    //   header: "Repeat Auction",
    //   form: [
    //     {
    //       title: "Restart size",
    //       inputType: "number",
    //       type: "input",
    //       name: "restartSize",
    //       symbol: "",
    //     },
    //   ],
    // },
    // {
    //   header: "Auction Visibility",
    //   form: [
    //     {
    //       title: "Display in the catalog after completion",
    //       name: "auctionVisibility",
    //       type: "check",
    //     },
    //   ],
    // },
  ];

  return {
    assetClassicForm,
    assetDutchForm,
  };
};

export const BIDSHEET_COLUMN: Json = {
  bid_price_bond_points: "Bid price (Bond points)",
  bid_price_USD: "Bid price (=USD)",
  asking_price_bond_points: "Asking price (Bond points)",
  asking_price_USD: "Asking price (=USD)",
};
