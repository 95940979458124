import type { IDateRange } from "./type";

import { atom } from "recoil";
import { defineds } from "components/date-range-picker/defineds";

const { startOfLast30days, endOfLast30days } = defineds;

export const DatePickerVisibleState = atom<boolean>({
  key: "date-picker-visible-state",
  default: false,
});

export const SelectedDateRangeState = atom<IDateRange[]>({
  key: "selected-date-range-state",
  default: [
    {
      startDate: startOfLast30days,
      endDate: endOfLast30days,
      selectionType: "custom",
      key: "selection",
    },
  ],
});

export const SelectedMultipleDateState = atom<string[]>({
  key: "selected-multiple-date-state",
  default: [],
});

export const showEmailNotificationState = atom<boolean>({
  key: "show-email-notification-sate",
  default: false,
});
