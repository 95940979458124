import React, { useState, useEffect, useCallback } from 'react';
import "./hubspot.scss";
import { useRecoilState, useRecoilValue } from 'recoil';
import { isFormVisibleState, userPersonalDetails } from '@states/user';
import NewModal from '@components/new-modal/new-modal';
import { Loader } from '@components/Loader2';
import { useNotification } from '@hooks/notification';

const HubSpotForm: React.FC = () => {
  const [isFormVisible, setIsFormVisible] = useRecoilState(isFormVisibleState);
  const userDetails = useRecoilValue(userPersonalDetails);
  const { successNotification} = useNotification();

  const [loading, setLoading] = useState(true);

  const handleHubspotClose = useCallback(()=>{
    setIsFormVisible(false)
  }, [])
  

  useEffect(() => {
    setLoading(true);
    if (isFormVisible) {
      const script = document.createElement('script');
      script.src = "//js.hsforms.net/forms/embed/v2.js";
      script.async = true;
      script.onload = () => {
        if ((window as any).hbspt) {
          (window as any).hbspt.forms.create({
            portalId: "43763470",
            formId: "d4d66e1c-5ffa-438b-a03e-ada909a85b23",
            target: "#hubspot-form-container",
            onFormSubmit: () => {
              setTimeout(() => {
              handleHubspotClose();
              successNotification("Thanks for submitting the form. We will get back to you shortly.");
            }, 600);
            },
            onFormReady: (formElement: HTMLElement) => {
              const prefillField = (selector: string, value: string) => {
                const field: any = formElement.querySelector(selector) as HTMLInputElement;
                if (field) {
                  field.value = value;
                  field?.focus();
                }
              };
              prefillField('input[name="phone"]', (userDetails?.phone || "") as string);
              prefillField('input[name="your_country_code"]', (userDetails?.countryCode || "") as string);
              prefillField('input[name="email"]', (userDetails?.email || "") as string);
              prefillField('input[name="firstname"]', (userDetails?.firstName || "") as string);
              prefillField('input[name="company"]', (userDetails?.onboardingData?.companyName || "") as string);
              prefillField('input[name="lastname"]', (userDetails?.lastName || "") as string);
            },
          });
        }
          setLoading(false)
      };
      document.body.appendChild(script);

      // Cleanup function to remove the script on component unmount
      return () => {
        document.body.removeChild(script);
      };
    }
  }, [isFormVisible]);

  return (
    <div>
     {isFormVisible && <NewModal
        isOpen={isFormVisible}
        closeModal={handleHubspotClose}
        modalName={"hubSpot-modal"}
        title=""
        isStopOutsideClick={true}
        closeOnEscBtn={false}
        className="Modal__wrapper exchange-modal-wrapper hubSpot-modal"
      >
         {loading ? (
            <div className="hubSpot-modal__loader"><Loader/></div>
          ) : (
            <div id="hubspot-form-container" className="hubSpot-modal__container"></div>
          )}
      </NewModal>}
    </div>
  );
};

export default HubSpotForm;
