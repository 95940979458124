import type { IDateRangePicker } from "./type";
import { FC, useCallback, MouseEvent } from "react";
import { DateRange, DefinedRange, RangeKeyDict } from "react-date-range";
import cn from "classnames";

import { Button } from "components";
import { defaultStaticRanges } from "./constants";
import useDarkMode from "use-dark-mode";

import "./date-range-picker.scss";
import styles from "./date-range-picker.module.sass";

export const DateRangePicker: FC<IDateRangePicker> = ({
  handleSubmit,
  range,
  handleChangeRange,
  rangeColor,
  setIsVisible,
}) => {
  const darkMode = useDarkMode();

  const handleClose = useCallback(
    (e: MouseEvent<HTMLDivElement, globalThis.MouseEvent>) => {
      e.stopPropagation();
      setIsVisible(false);
    },
    [setIsVisible]
  );
  const dateRangeClass = cn(
    "date-range-picker-overlay",
    styles.date_range_picker_overlay_dark
  );
  const DateoverlayContainer = cn(
    "overlay-container",
    styles.overlay_container_dark
  );

  const dateRangePicker = cn(
    "date-range-picker",
    styles.date_range_picker_dark,
    { darkMode: darkMode.value }
  );
  return (
    <>
      <div className={dateRangeClass} onClick={handleClose} />
      <div className={dateRangePicker}>
        <DateRange
          className="calender"
          editableDateInputs={true}
          onChange={(item: RangeKeyDict) => handleChangeRange(item, "manual")}
          moveRangeOnFirstSelection={false}
          ranges={range}
          showMonthAndYearPickers={false}
          rangeColors={rangeColor}
          retainEndDateOnFirstSelection={false}
          fixedHeight={false}
          maxDate={new Date()}
          showDateDisplay={false}
          showPreview={true}
        />
        <div className={DateoverlayContainer}>
          <DefinedRange
            className="DefinedRange--container"
            onChange={(item: RangeKeyDict) => handleChangeRange(item, "custom")}
            ranges={range}
            rangeColors={rangeColor}
            inputRanges={[]}
            staticRanges={defaultStaticRanges}
          />
          {/* <Button
            label="Apply"
            handleClick={handleSubmit}
            type="button__filled button__filled--primary button__large"
          /> */}
          <button
            onClick={handleSubmit}
            className="button__filled button__filled--primary-main button__large apply-button"
          >
            Apply
          </button>
        </div>
      </div>
    </>
  );
};
